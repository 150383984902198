<template>

    <div v-if="this.$isAuRegion()">
        <AuTreatmentHistory></AuTreatmentHistory>
    </div>
    <div v-else-if="this.$isUSRegion()">
        <UsTreatmentHistory></UsTreatmentHistory>
    </div>
    <div v-else>
        <NZTreatmentHistory></NZTreatmentHistory>
    </div>

</template>

<script>
import UsTreatmentHistory from './UsTreatmentHistory.vue';
import AuTreatmentHistory from './AuTreatmentHistory.vue';
import NZTreatmentHistory from './NZTreatmentHistory.vue';

export default {
    components: {
        UsTreatmentHistory,
        AuTreatmentHistory,
        NZTreatmentHistory
    }
}

</script>