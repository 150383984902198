<template>
  <vx-tooltip style="display: flex; justify-content: center;" text="View Detail">
    <vs-button
      style="float: left"
      @click="viewDetailHandler()"
      size="small"
      type="border"
      class="m-1"
    >
      View</vs-button
    >
    <!-- <vs-button
      style="float:left"
      type="border"
      size="small"
      @click="viewDetailHandler()"
      icon-pack="feather"
      icon="icon-eye"
      color="success"
      class="m-1"
    ></vs-button> -->
  </vx-tooltip>
</template>

<script>
export default {
  name: "ViewTreatmentHistoryDetailButton",
  props: {
    checkInId: {
      type: String
    },
    patientId: {
      type: String
    },
    dateRange: {
      type: String
    },
    month: {
      type: String
    },
    year: {
      type: Number
    },
    clinicId: {
      type: String
    },
    clinicName: {
      type: String
    },
    activeView: {
      type: String
    }
  },
  data() {
    return {
      orgId: null,
    }
  },
  methods: {
    viewDetailHandler() {
      const userType = this.$store.state.AppActiveUser.userType;
      const role =  localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );
      if( role === 'Org Owner'){
        this.$router.push({
          name: "OrgOwnerPatientTreatmentDetail",
          params: {
            id: this.patientId,
            checkinId: this.checkInId,
            org: this.orgId
          },
          query: {
            backRoute: "OrgOwnerDoctorEdit",
            clinicId: this.clinicId,
            clinicName: this.clinicName,
            year: this.year,
            month: this.month,
            dateRange: this.dateRange,
            activeView: this.activeView
          }
        });
      } else {
        if (userType === "doctor")
        this.$router.push({
          path: `/doctor/patients/${this.patientId}/treatment/${this.checkInId}`,
          query: {
            backRoute: "DoctorTreatmentHistory",
            clinicId: this.clinicId,
            clinicName: this.clinicName,
            year: this.year,
            month: this.month,
            dateRange: this.dateRange,
            activeView: this.activeView
          }
        });
      else if (userType === "nurse")
        this.$router.push({
          path: `/nurse/patients/${this.patientId}/treatment/${this.checkInId}`,
          query: {
            backRoute: "NurseTreatmentHistory",
            clinicId: this.clinicId,
            clinicName: this.clinicName,
            year: this.year,
            month: this.month,
            dateRange: this.dateRange,
            activeView: this.activeView
          }
        });
      else if (userType === "superAdmin")
        this.$router.push({
          path: `/super-admin/patients/${this.patientId}/treatment/${this.checkInId}`,
          query: {
            backRoute: "SuperAdminClinicTreatmentHistory",
            clinicId: this.clinicId,
            year: this.year,
            month: this.month,
            dateRange: this.dateRange,
            activeView: this.activeView
          }
        });
      else if (userType === "admin")
        this.$router.push({
          path: `/admin/patients/${this.patientId}/treatment/${this.checkInId}`,
          query: {
            backRoute: "AdminClinicTreatmentHistory",
            clinicId: this.clinicId,
            year: this.year,
            month: this.month,
            dateRange: this.dateRange,
            activeView: this.activeView
          }
        });
      else if (userType === "franchise" || userType === "subFranchise")
        this.$router.push({
          path: `/franchise/patients/${this.patientId}/treatment/${this.checkInId}`,
          query: {
            backRoute: "FranchiseClinicTreatmentHistory",
            clinicId: this.clinicId,
            year: this.year,
            month: this.month,
            dateRange: this.dateRange,
            activeView: this.activeView
          }
        });
      else {
        this.$router.push({
          path: `/clinic/patients/${this.patientId}/treatment/${this.checkInId}`,
          query: {
            backRoute: "ClinicTreatmentHistory",
            clinicId: this.clinicId,
            year: this.year,
            month: this.month,
            dateRange: this.dateRange,
            activeView: this.activeView
          }
        });
      }
      }
    }
  },
  created() {
    this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`);
  }
};
</script>